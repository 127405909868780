const localBaseUrl = "http://localhost:3000";
const devBaseUrl = "https://web.dev.iris.arup.com";
const dev2BaseUrl = "https://web2.dev.iris.arup.com";
const testBaseUrl = "https://web.test.iris.arup.com";
const prodBaseUrl = "https://web.iris.arup.com";

const AWS_CONFIG: any = {
  localhost: {
    COGNITO_DOMAIN: "iris-arup.auth.us-west-2.amazoncognito.com",
    USER_POOL_ID: "us-west-2_e86vlUEda",
    USER_POOL_WEBCLIENT_ID: "549df96i0chuqiko7jol73tv4d",
  },
  develop: {
    COGNITO_DOMAIN: "iris-arup.auth.us-west-2.amazoncognito.com",
    USER_POOL_ID: "us-west-2_e86vlUEda",
    USER_POOL_WEBCLIENT_ID: "549df96i0chuqiko7jol73tv4d",
  },
  test: {
    COGNITO_DOMAIN: "iris-arup-uat.auth.us-west-2.amazoncognito.com",
    USER_POOL_ID: "us-west-2_fV4QbGPaF",
    USER_POOL_WEBCLIENT_ID: "5e3lkjvns6t8mu74a1vqop5dp2",
  },
  production: {
    COGNITO_DOMAIN: "iris-arup-prod.auth.us-west-2.amazoncognito.com",
    USER_POOL_ID: "us-west-2_XoPZf2KgS",
    USER_POOL_WEBCLIENT_ID: "1huqcpme8tqq9g39di75up3jnh",
  },
};

const localConfig = {
  type: "localhost",
  REDIRECT_SIGNIN_URL: localBaseUrl,
  REDIRECT_SIGNOUT_URL: localBaseUrl,
  ...AWS_CONFIG["develop"],
};

const devConfig = {
  type: "develop",
  REDIRECT_SIGNIN_URL: devBaseUrl,
  REDIRECT_SIGNOUT_URL: devBaseUrl,
  ...AWS_CONFIG["develop"],
};
const dev2Config = {
  type: "develop",
  REDIRECT_SIGNIN_URL: dev2BaseUrl,
  REDIRECT_SIGNOUT_URL: dev2BaseUrl,
  ...AWS_CONFIG["develop"],
};

const testConfig = {
  type: "test",
  REDIRECT_SIGNIN_URL: testBaseUrl,
  REDIRECT_SIGNOUT_URL: testBaseUrl,
  ...AWS_CONFIG["test"],
};

const prodConfig = {
  type: "production",
  REDIRECT_SIGNIN_URL: prodBaseUrl,
  REDIRECT_SIGNOUT_URL: prodBaseUrl,
  ...AWS_CONFIG["production"],
};

const configLookup: any = {
  localhost: localConfig,
  // develop
  develop: devConfig,
  "web.dev.iris.arup.com": devConfig,
  "https://web.dev.iris.arup.com": devConfig,
  "webclient.dev.iris.arup.com": devConfig,
  "https://webclient.dev.iris.arup.com": devConfig,
  "web2.dev.iris.arup.com": dev2Config,
  "https://web2.dev.iris.arup.com": dev2Config,
  // test
  test: testConfig,
  "web.test.iris.arup.com": testConfig,
  "https://web.test.iris.arup.com": testConfig,
  "webclient.test.iris.arup.com": testConfig,
  "https://webclient.test.iris.arup.com": testConfig,
  // prod
  production: prodConfig,
  "web.iris.arup.com": prodConfig,
  "https://web.iris.arup.com": prodConfig,
  "webclient.iris.arup.com": prodConfig,
  "https://webclient.iris.arup.com": prodConfig,
};

const getEnvironmentConfiguration = () => {
  const client = process.env.NEXT_PUBLIC_CLIENT;
  const clientHost = client || window.location.hostname || "develop";
  const clientConfig = configLookup[clientHost];

  const server = process.env.NEXT_PUBLIC_SERVER;
  const serverHost = server || window.location.hostname || "develop";
  const serverConfig = configLookup[serverHost];

  return {
    ...clientConfig,
    ...AWS_CONFIG[serverConfig.type],
  };
};

export const getAWSConfiguration = () => {
  const env = getEnvironmentConfiguration();

  return {
    Auth: {
      oauth: {
        domain: `${env.COGNITO_DOMAIN}`,
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: `${env.REDIRECT_SIGNIN_URL}`,
        redirectSignOut: `${env.REDIRECT_SIGNOUT_URL}`,
        responseType: "code",
      },
      mandatorySignIn: false,
      region: "us-west-2",
      userPoolId: `${env.USER_POOL_ID}`,
      userPoolWebClientId: `${env.USER_POOL_WEBCLIENT_ID}`,
      authenticationFlowType: "USER_PASSWORD_AUTH",
    },
  };
};

const getBaseUrl = () => {
  let baseUrl = process.env.NEXT_PUBLIC_SERVER;
  if (!baseUrl && typeof window !== "undefined") {
    baseUrl = window.location.origin;
  }
  return baseUrl || devBaseUrl;
};

export const getServicesConfiguration = () => {
  const baseUrl = getBaseUrl();

  return {
    services: {
      CLIENT_SVC_URL:
        process.env.NEXT_PUBLIC_CLIENT_SVC_URL || `${baseUrl}/client_svc`,
      HAZARD_SVC_URL:
        process.env.NEXT_PUBLIC_HAZARD_SVC_URL || `${baseUrl}/hazard_svc`,
      ASSET_SVC_URL:
        process.env.NEXT_PUBLIC_ASSET_SVC_URL || `${baseUrl}/asset_svc`,
      RECOMMENDATION_SVC_URL:
        process.env.NEXT_PUBLIC_RECOMMENDATION_SVC_URL || `${baseUrl}/rec_svc`,
      HAPI_SVC_URL:
        process.env.NEXT_PUBLIC_HAPI_SVC_URL || `${baseUrl}/hapi_svc`,
      OPEN_SEARCH_URL:
        process.env.NEXT_PUBLIC_OPEN_SEARCH_SVC_URL || `${baseUrl}/search_svc`,
    },
  };
};
