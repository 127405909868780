/** AdjacencyMapSetting */
export interface AdjacencyMapSetting {
  /**
   * Zoom Level
   * The default zoom level for the map.
   * @min 0
   * @max 23
   * @example 10
   */
  zoom_level: number;
  /**
   * The default background map to use. Options: satellite, street.
   * @example "street"
   */
  background_map: BackgroundMapEnum;
  /**
   * The type of clustering to apply to the data points. Options: bubble, decluster.
   * @example "bubble"
   */
  clustering?: ClusterEnum;
  /**
   * Center Point
   * Latitude and Longitude that the map should center on [lat, lon].
   * @maxItems 2
   * @minItems 2
   * @example [43.234,44.12]
   */
  center_point?: number[];
  /**
   * Name
   * @default "adjacency_map"
   */
  name?: string;
}

/** AdminUpdateUserAttributesRequestModel */
export interface AdminUpdateUserAttributesRequestModel {
  /**
   * Email
   * Email of the user
   * @example "john.smith@arup.com"
   */
  email?: string;
  /**
   * Name
   * Name of the user
   * @example "John Smith"
   */
  name?: string;
  /**
   * Organization Id
   * The ID of the organization the user belongs to
   * @example "4e7d2aa3-f72b-43e8-bb80-144866a055f1"
   */
  organization_id?: string;
}

/** AggregateStatisticsSetting */
export interface AggregateStatisticsSetting {
  /**
   * Name
   * @default "aggregate_statistics"
   */
  name?: string;
  /**
   * Total Assets
   * Whether to display the total assets
   * @example true
   */
  total_assets: boolean;
  /**
   * Total Population
   * Whether to display the total population
   * @example true
   */
  total_population: boolean;
  /**
   * Total Asset Value
   * Whether to display the total asset value
   * @example true
   */
  total_asset_value: boolean;
  /**
   * Total Replacement Value
   * Whether to display the total replacement value
   * @example true
   */
  total_replacement_value: boolean;
  /**
   * Total Occupied Area
   * Whether to display the total occupied area
   * @example true
   */
  total_occupied_area: boolean;
  /**
   * Top Uses
   * Whether to display the top uses
   * @example true
   */
  top_uses: boolean;
}

/**
 * AllHazardsEnum
 * An enumeration.
 */
export enum AllHazardsEnum {
  AllHazards = "all_hazards",
}

/** AssetHeaderLabelSetting */
export interface AssetHeaderLabelSetting {
  /**
   * Name
   * @default "asset_header_labels"
   */
  name?: string;
  /**
   * Area
   * Custom label for area on asset page
   * @minLength 1
   * @maxLength 30
   * @example "Total Footprint"
   */
  area: string;
  /**
   * Construction Year
   * Custom label for construction year on asset page
   * @minLength 1
   * @maxLength 30
   * @example "Construction Year"
   */
  construction_year: string;
  /**
   * Floors
   * Custom label for floors on asset page
   * @minLength 1
   * @maxLength 30
   * @example "Floors"
   */
  floors: string;
  /**
   * Asset Name
   * Custom label for name on asset page
   * @minLength 1
   * @maxLength 30
   * @example "Name"
   */
  asset_name: string;
  /**
   * Nickname
   * Custom label for nickname on asset page
   * @minLength 1
   * @maxLength 30
   * @example "Nickname"
   */
  nickname: string;
  /**
   * Population
   * Custom label for population on asset page
   * @minLength 1
   * @maxLength 30
   * @example "Occupancy"
   */
  population: string;
  /**
   * Replacement Value
   * Custom label for replacement value on asset page
   * @minLength 1
   * @maxLength 30
   * @example "Replacement Value"
   */
  replacement_value: string;
  /**
   * Status
   * Custom label for status on asset page
   * @minLength 1
   * @maxLength 30
   * @example "Status"
   */
  status: string;
  /**
   * Primary Use
   * Custom label for primary use on asset page
   * @minLength 1
   * @maxLength 30
   * @example "Primary Use"
   */
  primary_use: string;
  /**
   * Ownership
   * Custom label for ownership on asset page
   * @minLength 1
   * @maxLength 30
   * @example "Ownership"
   */
  ownership: string;
  /**
   * Business Group
   * Custom label for business group on asset page
   * @minLength 1
   * @maxLength 30
   * @example "Business Group"
   */
  business_group: string;
}

/**
 * BackgroundMapEnum
 * An enumeration.
 */
export enum BackgroundMapEnum {
  Satellite = "satellite",
  Street = "street",
}

/** BaseDataGridSetting */
export interface BaseDataGridSetting {
  /**
   * Columns
   * Columns in the datagrid that should be visible or not.
   */
  columns: InputParameter[];
  /**
   * Filters
   * Any filters to apply to the data in the data grid.
   */
  filters: InputParameter[];
  /**
   * General
   * Any other general settings applying to the data grid.
   */
  general: object;
}

/** BaseGraphSetting */
export interface BaseGraphSetting {
  /**
   * X Min
   * The lower limit for the x-axis.
   * @example 0
   */
  x_min?: number;
  /**
   * X Max
   * The upper limit for the x-axis.
   * @example 20
   */
  x_max?: number;
  /**
   * Y Min
   * The lower limit for the y-axis.
   * @example 0
   */
  y_min?: number;
  /**
   * Y Max
   * The upper limit for the y-axis.
   * @example 10
   */
  y_max?: number;
}

/**
 * ClusterEnum
 * An enumeration.
 */
export enum ClusterEnum {
  Bubble = "bubble",
  Decluster = "decluster",
}

/**
 * ConsequenceType
 * An enumeration.
 */
export enum ConsequenceType {
  FINANCIAL_LOSS = "FINANCIAL_LOSS",
  INJURIES = "INJURIES",
  FATALITIES = "FATALITIES",
  FUNCTIONAL_RECOVERY = "FUNCTIONAL_RECOVERY",
  IMMEDIATE_OCCUPANCY = "IMMEDIATE_OCCUPANCY",
  FULL_RECOVERY = "FULL_RECOVERY",
  COLLAPSE = "COLLAPSE",
  ECONOMIC_LOSS = "ECONOMIC_LOSS",
  INVENTORY_LOSS = "INVENTORY_LOSS",
  LIFE_SAFETY = "LIFE_SAFETY",
  AGGREGATE_LIFE_SAFETY = "AGGREGATE_LIFE_SAFETY",
  HEALTH_AND_WELLNESS = "HEALTH_AND_WELLNESS",
  DAMAGE = "DAMAGE",
  SEVERE_WEATHER_WARNING = "SEVERE_WEATHER_WARNING",
  HAZARD_RATING = "HAZARD_RATING",
  REPAIR_COST = "REPAIR_COST",
  DOWNTIME = "DOWNTIME",
  AllConsequences = "all_consequences",
}

/** CreateOrganizationRequestModel */
export interface CreateOrganizationRequestModel {
  /**
   * Name
   * Name of the organization
   * @example "Client #1"
   */
  name: string;
  /**
   * Logo
   * URL of logo for organization
   */
  logo: string;
  /**
   * Description
   * Description of the organization.
   * @example "This is an organization."
   */
  description?: string;
}

/** CreateOrganizationResponseModel */
export interface CreateOrganizationResponseModel {
  /**
   * Name
   * Name of the organization
   * @example "Client #1"
   */
  name: string;
  /**
   * Logo
   * URL of logo for organization
   */
  logo: string;
  /**
   * Description
   * Description of the organization.
   * @example "This is an organization."
   */
  description?: string;
  /**
   * Id
   * Id of the organization
   */
  id: string;
}

/** CreateSettingsResponse */
export interface CreateSettingsResponse {
  /**
   * Views
   * The views to define settings for. Key options: asset, group, portfolio, organization.
   */
  views: Record<string, ViewModel>;
  /**
   * Id
   * Identifier of the settings object.
   */
  id: string;
}

/** CreateUserRequestModel */
export interface CreateUserRequestModel {
  /**
   * Email
   * Email of the user
   * @example "john.smith@arup.com"
   */
  email: string;
  /**
   * Name
   * Name of the user
   * @example "John Smith"
   */
  name?: string;
  /**
   * Organization Id
   * The ID of the organization the user belongs to
   * @example "4e7d2aa3-f72b-43e8-bb80-144866a055f1"
   */
  organization_id: string;
  /**
   * Username
   * Username for account to assign to Organization
   * @example "john.smith@arup.com"
   */
  username: string;
  /**
   * The user Role(s) as defined in Cognito
   * @default ["Guest"]
   * @example ["Admin"]
   */
  roles?: UserRoleEnum[];
  /**
   * Temp Password
   * Temporary password for the user
   * @example "Passw0rd!"
   */
  temp_password: string;
}

/** CreateUserResponse */
export interface CreateUserResponse {
  /**
   * Email
   * Email of the user
   * @example "john.smith@arup.com"
   */
  email: string;
  /**
   * Name
   * Name of the user
   * @example "John Smith"
   */
  name?: string;
  /**
   * Organization Id
   * The ID of the organization the user belongs to
   * @example "4e7d2aa3-f72b-43e8-bb80-144866a055f1"
   */
  organization_id: string;
  /**
   * Username
   * Username for account to assign to Organization
   * @example "john.smith@arup.com"
   */
  username: string;
  /**
   * The user Role(s) as defined in Cognito
   * @default ["Guest"]
   * @example ["Admin"]
   */
  roles?: UserRoleEnum[];
  /**
   * Enabled
   * Whether the user account is enabled (active)
   * @example true
   */
  enabled: boolean;
  /**
   * Created At
   * Datetime when the user account was created
   * @example "2022-02-01T14:35:02.683000+01:00"
   */
  created_at: string;
  /**
   * Updated At
   * Last datetime when the user account was updated
   * @example "2022-02-01T14:35:02.683000+01:00"
   */
  updated_at: string;
}

/** CreateViewSettingsRequest */
export interface CreateViewSettingsRequest {
  /**
   * Settings
   * The setting definitions for different types of views. Key options: adjacency_map, single_asset_map, group_asset_map, risk_ratings_graph, risk_metrics_comparison_graph, highest_risk_assets_table, risk_surface_area_graph, aggregate_statistics, header_labels, asset_header_labels, portfolio_map, risk_threshold, asset_data_grid, units.
   */
  settings: Record<
    string,
    | SingleAssetMapSetting
    | GroupAssetMapSetting
    | AdjacencyMapSetting
    | RiskRatingsGraphSetting
    | RiskMetricsGraphSetting
    | HighestRiskTableSetting
    | RiskSurfaceAreaGraphSetting
    | AggregateStatisticsSetting
    | HeaderLabelSetting
    | AssetHeaderLabelSetting
    | PortfolioMapSetting
    | RiskThresholdSetting
    | BaseDataGridSetting
    | UnitsSetting
  >;
}

/** DefaultInputValueRule */
export interface DefaultInputValueRule {
  /** Value */
  value?: boolean | number | string | any[];
  /**
   * Condition
   * Specification of the parameter
   */
  condition?: Specification;
}

/** FeatureFlag */
export interface FeatureFlag {
  /**
   * Name
   * The name of the feature flag.
   */
  name: string;
  /**
   * Description
   * The description of the feature flag.
   */
  description?: string;
  /**
   * Enabled
   * Whether the feature flag is enabled.
   */
  enabled: boolean;
  /**
   * Attributes
   * Feature flags attributes
   */
  attributes?: Record<string, FeatureFlagAttribute>;
}

/** FeatureFlagAttribute */
export interface FeatureFlagAttribute {
  /**
   * The type of the attribute.
   * @example "number"
   */
  type: FeatureFlagAttributeType;
  /**
   * Value
   * The value of the attribute. Supported type values str, int, float and bool.
   * @example 100
   */
  value?: any;
}

/**
 * FeatureFlagAttributeType
 * Possible types of a feature flag attribute.
 *
 * Each enum member is associated with a tuple containing the type name as a string and a tuple of the corresponding Python types.
 */
export enum FeatureFlagAttributeType {
  Number = "number",
  String = "string",
  Boolean = "boolean",
}

/** GroupAssetMapSetting */
export interface GroupAssetMapSetting {
  /**
   * Zoom Level
   * The default zoom level for the map.
   * @min 0
   * @max 23
   * @example 10
   */
  zoom_level: number;
  /**
   * The default background map to use. Options: satellite, street.
   * @example "street"
   */
  background_map: BackgroundMapEnum;
  /**
   * The type of clustering to apply to the data points. Options: bubble, decluster.
   * @example "bubble"
   */
  clustering?: ClusterEnum;
  /**
   * Center Point
   * Latitude and Longitude that the map should center on [lat, lon].
   * @maxItems 2
   * @minItems 2
   * @example [43.234,44.12]
   */
  center_point?: number[];
  /**
   * Name
   * @default "group_asset_map"
   */
  name?: string;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/**
 * HazardsEnum
 * An enumeration.
 */
export enum HazardsEnum {
  GeophysicalSeismic = "geophysical_seismic",
  GeophysicalLiquefaction = "geophysical_liquefaction",
  GeophysicalTsunami = "geophysical_tsunami",
  GeophysicalLandslide = "geophysical_landslide",
  GeophysicalVolcano = "geophysical_volcano",
  GeophysicalAvalanche = "geophysical_avalanche",
  HydrologicalStormwaterFlooding = "hydrological_stormwater_flooding",
  HydrologicalRiverineFlooding = "hydrological_riverine_flooding",
  HydrologicalCoastalFloodingAndSeaLevelRise = "hydrological_coastal_flooding_and_sea_level_rise",
  ClimatologicalExtremeHeat = "climatological_extreme_heat",
  ClimatologicalDrought = "climatological_drought",
  ClimatologicalWildfire = "climatological_wildfire",
  ClimatologicalWildfireSmoke = "climatological_wildfire_smoke",
  ClimatologicalExtremeCold = "climatological_extreme_cold",
  MeteorologicalIntegratedWind = "meteorological_integrated_wind",
  MeteorologicalCyclonicWind = "meteorological_cyclonic_wind",
  MeteorologicalThunderstormsLocalStorms = "meteorological_thunderstorms_local_storms",
  MeteorologicalExtraTropicalDepressions = "meteorological_extra_tropical_depressions",
  MeteorologicalTornado = "meteorological_tornado",
  MeteorologicalDustStorm = "meteorological_dust_storm",
  MeteorologicalHail = "meteorological_hail",
  MeteorologicalSnow = "meteorological_snow",
  MeteorologicalIceStorm = "meteorological_ice_storm",
  AdjacencyAirport = "adjacency_airport",
  AdjacencyHighway = "adjacency_highway",
  AdjacencyRailway = "adjacency_railway",
  AdjacencyPipeline = "adjacency_pipeline",
  AdjacencyIndustrialFacilities = "adjacency_industrial_facilities",
  AdjacencyNuclear = "adjacency_nuclear",
  AdjacencyDams = "adjacency_dams",
  AdjacencyRoads = "adjacency_roads",
  AttributeValues = "attribute_values",
  TechnologicalStructuralIntegrity = "technological_structural_integrity",
  TechnologicalFire = "technological_fire",
  HydrologicalGroundwaterFlooding = "hydrological_groundwater_flooding",
  IntentionalTerrorism = "intentional_terrorism",
  IntentionalCriminality = "intentional_criminality",
  IntentionalStateOnStateMilitaryAttack = "intentional_state_on_state_military_attack",
}

/** HeaderLabelSetting */
export interface HeaderLabelSetting {
  /**
   * Name
   * @default "header_labels"
   */
  name?: string;
  /**
   * Total Assets
   * Custom label for total assets
   * @minLength 1
   * @maxLength 30
   * @example "Total Assets"
   */
  total_assets: string;
  /**
   * Total Population
   * Custom label for population
   * @minLength 1
   * @maxLength 30
   * @example "Total Population"
   */
  total_population: string;
  /**
   * Total Asset Value
   * Custom label for total asset value
   * @minLength 1
   * @maxLength 30
   * @example "Total Value"
   */
  total_asset_value: string;
  /**
   * Total Replacement Value
   * Custom label for total replacement value
   * @minLength 1
   * @maxLength 30
   * @example "Total Replacement Value"
   */
  total_replacement_value: string;
  /**
   * Total Occupied Area
   * Custom label for total occupied area
   * @minLength 1
   * @maxLength 30
   * @example "Occupied Area"
   */
  total_occupied_area: string;
  /**
   * Top Uses
   * Custom label for the top uses
   * @minLength 1
   * @maxLength 30
   * @example "Uses"
   */
  top_uses: string;
}

/** HighestRiskTableSetting */
export interface HighestRiskTableSetting {
  /**
   * Name
   * @default "highest_risk_assets_table"
   */
  name?: string;
  /**
   * The default consequence type to show.
   *             Options: [<ConsequenceType.ECONOMIC_LOSS: 'ECONOMIC_LOSS'>, <ConsequenceType.DOWNTIME: 'DOWNTIME'>, <ConsequenceType.INVENTORY_LOSS: 'INVENTORY_LOSS'>, <ConsequenceType.LIFE_SAFETY: 'LIFE_SAFETY'>, <ConsequenceType.AGGREGATE_LIFE_SAFETY: 'AGGREGATE_LIFE_SAFETY'>, <ConsequenceType.HEALTH_AND_WELLNESS: 'HEALTH_AND_WELLNESS'>, <ConsequenceType.DAMAGE: 'DAMAGE'>, <ConsequenceType.SEVERE_WEATHER_WARNING: 'SEVERE_WEATHER_WARNING'>, <ConsequenceType.ALL_CONSEQUENCES: 'all_consequences'>].
   *
   * @example "all_consequences"
   */
  consequence: ConsequenceType;
  /**
   * Hazard
   * The default hazard to highlight.
   *             Options: geophysical_seismic, geophysical_liquefaction, geophysical_tsunami, geophysical_landslide, geophysical_volcano, geophysical_avalanche, hydrological_stormwater_flooding, hydrological_riverine_flooding, hydrological_coastal_flooding_and_sea_level_rise, climatological_extreme_heat, climatological_drought, climatological_wildfire, climatological_wildfire_smoke, climatological_extreme_cold, meteorological_integrated_wind, meteorological_cyclonic_wind, meteorological_thunderstorms_local_storms, meteorological_extra_tropical_depressions, meteorological_tornado, meteorological_dust_storm, meteorological_hail, meteorological_snow, meteorological_ice_storm, adjacency_airport, adjacency_highway, adjacency_railway, adjacency_pipeline, adjacency_industrial_facilities, adjacency_nuclear, adjacency_dams, adjacency_roads, attribute_values, technological_structural_integrity, technological_fire, hydrological_groundwater_flooding, intentional_terrorism, intentional_criminality, intentional_state_on_state_military_attack, all_hazards.
   * @example "all_hazards"
   */
  hazard: HazardsEnum | AllHazardsEnum;
}

/** InputParameter */
export interface InputParameter {
  /**
   * Id
   * Unique identifier for the field
   */
  id: string;
  /**
   * Value
   * Value of the setting
   */
  value?: boolean | number | string | any[];
  /** An enumeration. */
  field_type: InputParameterFieldType;
  /**
   * Label
   * Label to display for the field
   */
  label: string;
  /**
   * Description
   * Description of the field
   */
  description?: string;
  /**
   * Options
   * List of options
   * @default []
   */
  options?: InputParameterOption[];
  /**
   * Visible
   * Whether the field is visible or not
   * @default true
   */
  visible?: boolean;
  /**
   * Disabled
   * Whether the field is disabled or not
   * @default false
   */
  disabled?: boolean;
  /**
   * Help Text
   * Help text for the field
   */
  help_text?: string;
  /**
   * Required
   * Whether the field is required or not
   * @default false
   */
  required?: boolean;
  /**
   * Visibility Condition
   * Visibility rule for the field
   */
  visibility_condition?: Specification;
  /**
   * Validation
   * Validation rule for the field
   */
  validation?: Specification;
  /**
   * Default Value
   * Default value for the field
   */
  default_value?: boolean | number | string | any[];
  /**
   * Default Value Rules
   * Default value for the field
   */
  default_value_rules?: DefaultInputValueRule[];
  /**
   * Read Only
   * Whether the field is read-only or not
   * @default false
   */
  read_only?: boolean;
}

/**
 * InputParameterFieldType
 * An enumeration.
 */
export enum InputParameterFieldType {
  SINGLESELECT = "SINGLESELECT",
  MULTISELECT = "MULTISELECT",
}

/** InputParameterOption */
export interface InputParameterOption {
  /**
   * Value
   * Value of the option
   */
  value?: string;
  /**
   * Label
   * Label to display for the option
   */
  label?: string;
  /**
   * Description
   * Description of the option
   */
  description?: string;
  /**
   * Image Url
   * Image URL for the option
   */
  image_url?: string;
  /**
   * Validation
   * Validation rule
   */
  validation?: Specification;
}

/**
 * InstanceViewEnum
 * An enumeration.
 */
export enum InstanceViewEnum {
  Asset = "asset",
  Group = "group",
}

/**
 * PaginatedOrganizationResponseModel
 * This model should be extended to create a standard paginated response for our apis.
 */
export interface PaginatedOrganizationResponseModel {
  /**
   * Results
   * Page of results requested
   */
  results: CreateOrganizationResponseModel[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   * @example "a12vsf"
   */
  cursor?: string;
}

/**
 * PlotOrientationEnum
 * An enumeration.
 */
export enum PlotOrientationEnum {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

/** PortfolioMapSetting */
export interface PortfolioMapSetting {
  /**
   * Zoom Level
   * The default zoom level for the map.
   * @min 0
   * @max 23
   * @example 10
   */
  zoom_level: number;
  /**
   * The default background map to use. Options: satellite, street.
   * @example "street"
   */
  background_map: BackgroundMapEnum;
  /**
   * The type of clustering to apply to the data points. Options: bubble, decluster.
   * @example "bubble"
   */
  clustering?: ClusterEnum;
  /**
   * Center Point
   * Latitude and Longitude that the map should center on [lat, lon].
   * @maxItems 2
   * @minItems 2
   * @example [43.234,44.12]
   */
  center_point?: number[];
  /**
   * Name
   * @default "portfolio_map"
   */
  name?: string;
}

/**
 * RiskMetricHazards
 * An enumeration.
 */
export enum RiskMetricHazards {
  Seismic = "seismic",
  Wind = "wind",
  Flood = "flood",
  Tornado = "tornado",
}

/** RiskMetricsGraphSetting */
export interface RiskMetricsGraphSetting {
  /**
   * Name
   * @default "risk_metrics_comparison_graph"
   */
  name?: string;
  /**
   * Limits
   * The limits on the graphs for the different consequences and metrics.
   */
  limits: Record<string, Record<string, BaseGraphSetting>>;
  /**
   * The default hazard to highlight. Options: seismic, wind, flood, tornado.
   * @example "all_hazards"
   */
  hazard: RiskMetricHazards;
}

/** RiskRatingsGraphSetting */
export interface RiskRatingsGraphSetting {
  /**
   * X Min
   * The lower limit for the x-axis.
   * @example 0
   */
  x_min?: number;
  /**
   * X Max
   * The upper limit for the x-axis.
   * @example 20
   */
  x_max?: number;
  /**
   * Y Min
   * The lower limit for the y-axis.
   * @example 0
   */
  y_min?: number;
  /**
   * Y Max
   * The upper limit for the y-axis.
   * @example 10
   */
  y_max?: number;
  /**
   * Name
   * @default "risk_ratings_graph"
   */
  name?: string;
  /**
   * The default consequence type to show. Options: [<ConsequenceType.ECONOMIC_LOSS: 'ECONOMIC_LOSS'>, <ConsequenceType.DOWNTIME: 'DOWNTIME'>, <ConsequenceType.INVENTORY_LOSS: 'INVENTORY_LOSS'>, <ConsequenceType.LIFE_SAFETY: 'LIFE_SAFETY'>, <ConsequenceType.AGGREGATE_LIFE_SAFETY: 'AGGREGATE_LIFE_SAFETY'>, <ConsequenceType.HEALTH_AND_WELLNESS: 'HEALTH_AND_WELLNESS'>, <ConsequenceType.DAMAGE: 'DAMAGE'>].
   * @example "downtime"
   */
  consequence: ConsequenceType;
  /**
   * Hazard
   * The default hazard to highlight.
   *             Options: geophysical_seismic, geophysical_liquefaction, geophysical_tsunami, geophysical_landslide, geophysical_volcano, geophysical_avalanche, hydrological_stormwater_flooding, hydrological_riverine_flooding, hydrological_coastal_flooding_and_sea_level_rise, climatological_extreme_heat, climatological_drought, climatological_wildfire, climatological_wildfire_smoke, climatological_extreme_cold, meteorological_integrated_wind, meteorological_cyclonic_wind, meteorological_thunderstorms_local_storms, meteorological_extra_tropical_depressions, meteorological_tornado, meteorological_dust_storm, meteorological_hail, meteorological_snow, meteorological_ice_storm, adjacency_airport, adjacency_highway, adjacency_railway, adjacency_pipeline, adjacency_industrial_facilities, adjacency_nuclear, adjacency_dams, adjacency_roads, attribute_values, technological_structural_integrity, technological_fire, hydrological_groundwater_flooding, intentional_terrorism, intentional_criminality, intentional_state_on_state_military_attack, all_hazards.
   * @example "all_hazards"
   */
  hazard: HazardsEnum | AllHazardsEnum;
  /**
   * The default orientation of the graph. Options: horizontal, vertical.
   * @example "horizontal"
   */
  plot_orientation: PlotOrientationEnum;
}

/** RiskSurfaceAreaGraphSetting */
export interface RiskSurfaceAreaGraphSetting {
  /**
   * X Min
   * The lower limit for the x-axis.
   * @example 0
   */
  x_min?: number;
  /**
   * X Max
   * The upper limit for the x-axis.
   * @example 20
   */
  x_max?: number;
  /**
   * Y Min
   * The lower limit for the y-axis.
   * @example 0
   */
  y_min?: number;
  /**
   * Y Max
   * The upper limit for the y-axis.
   * @example 10
   */
  y_max?: number;
  /**
   * Name
   * @default "risk_surface_area_graph"
   */
  name?: string;
  /**
   * The default hazard to highlight. Options: geophysical_seismic, geophysical_liquefaction, geophysical_tsunami, geophysical_landslide, geophysical_volcano, geophysical_avalanche, hydrological_stormwater_flooding, hydrological_riverine_flooding, hydrological_coastal_flooding_and_sea_level_rise, climatological_extreme_heat, climatological_drought, climatological_wildfire, climatological_wildfire_smoke, climatological_extreme_cold, meteorological_integrated_wind, meteorological_cyclonic_wind, meteorological_thunderstorms_local_storms, meteorological_extra_tropical_depressions, meteorological_tornado, meteorological_dust_storm, meteorological_hail, meteorological_snow, meteorological_ice_storm, adjacency_airport, adjacency_highway, adjacency_railway, adjacency_pipeline, adjacency_industrial_facilities, adjacency_nuclear, adjacency_dams, adjacency_roads, attribute_values, technological_structural_integrity, technological_fire, hydrological_groundwater_flooding, intentional_terrorism, intentional_criminality, intentional_state_on_state_military_attack.
   * @example "geophysical_seismic"
   */
  hazard: HazardsEnum;
}

/** RiskThreshold */
export interface RiskThreshold {
  /**
   * Unacceptable
   * Unacceptable risk threshold score. Anything for which the risk score is equal or abovethe unacceptable threshold score is going to be considered unacceptable across the iris app.
   * @min -1
   * @max 9
   * @example 4
   */
  unacceptable: number;
  /**
   * Tolerable
   * Tolerable risk threshold score, Anything for which the risk score is equal or belowthe unacceptable threshold score is going to be considered tolerable across the iris app.
   * @min -1
   * @max 8
   * @example 3
   */
  tolerable?: number;
}

/** RiskThresholdSetting */
export interface RiskThresholdSetting {
  /**
   * Name
   * @default "risk_threshold"
   */
  name?: string;
  /**
   * Economic Loss
   * Risk thresholds for the economic loss consequence.
   */
  ECONOMIC_LOSS: RiskThreshold;
  /**
   * Downtime
   * Risk threshold for the downtime consequence.
   */
  DOWNTIME: RiskThreshold;
  /**
   * Inventory Loss
   * Risk threshold for the inventory loss consequence.
   */
  INVENTORY_LOSS: RiskThreshold;
  /**
   * Life Safety
   * Risk threshold for the individual life safety consequence.
   */
  LIFE_SAFETY: RiskThreshold;
  /**
   * Aggregate Life Safety
   * Risk threshold for the aggregate life safety consequence.
   */
  AGGREGATE_LIFE_SAFETY: RiskThreshold;
  /**
   * Health And Wellness
   * Risk threshold for the health and wellness consequence.
   */
  HEALTH_AND_WELLNESS: RiskThreshold;
  /**
   * Damage
   * Risk threshold for the damage consequence.
   */
  DAMAGE: RiskThreshold;
  /**
   * Severe Weather Warning
   * Risk threshold for the severe weather warning consequence.
   */
  SEVERE_WEATHER_WARNING: RiskThreshold;
}

/**
 * SettingNameEnum
 * An enumeration.
 */
export enum SettingNameEnum {
  AdjacencyMap = "adjacency_map",
  SingleAssetMap = "single_asset_map",
  GroupAssetMap = "group_asset_map",
  RiskRatingsGraph = "risk_ratings_graph",
  RiskMetricsComparisonGraph = "risk_metrics_comparison_graph",
  HighestRiskAssetsTable = "highest_risk_assets_table",
  RiskSurfaceAreaGraph = "risk_surface_area_graph",
  AggregateStatistics = "aggregate_statistics",
  HeaderLabels = "header_labels",
  AssetHeaderLabels = "asset_header_labels",
  PortfolioMap = "portfolio_map",
  RiskThreshold = "risk_threshold",
  AssetDataGrid = "asset_data_grid",
  Units = "units",
}

/** SingleAssetMapSetting */
export interface SingleAssetMapSetting {
  /**
   * Zoom Level
   * The default zoom level for the map.
   * @min 0
   * @max 23
   * @example 10
   */
  zoom_level: number;
  /**
   * The default background map to use. Options: satellite, street.
   * @example "street"
   */
  background_map: BackgroundMapEnum;
  /**
   * The type of clustering to apply to the data points. Options: bubble, decluster.
   * @example "bubble"
   */
  clustering?: ClusterEnum;
  /**
   * Center Point
   * Latitude and Longitude that the map should center on [lat, lon].
   * @maxItems 2
   * @minItems 2
   * @example [43.234,44.12]
   */
  center_point?: number[];
  /**
   * Name
   * @default "single_asset_map"
   */
  name?: string;
}

/** Specification */
export type Specification = object;

/** UnitsSetting */
export interface UnitsSetting {
  /**
   * Name
   * @default "units"
   */
  name?: string;
  /**
   * Title
   * The title of the units setting.
   * @example "Units"
   */
  title: string;
  /**
   * System
   * The system for which the units are defined.
   */
  system: InputParameter[];
  /**
   * Imperial
   * List of units for imperial system.
   */
  IMPERIAL: InputParameter[];
  /**
   * Metric
   * List of units for metric system.
   */
  METRIC: InputParameter[];
}

/** UpdateOrganizationSettingsRequest */
export interface UpdateOrganizationSettingsRequest {
  /**
   * Views
   * The views to define settings for. Key options: asset, group, portfolio, organization.
   */
  views: Record<string, ViewModel>;
}

/** UpdateUserAttributesRequestModel */
export interface UpdateUserAttributesRequestModel {
  /**
   * Email
   * Email of the user
   * @example "john.smith@arup.com"
   */
  email?: string;
  /**
   * Name
   * Name of the user
   * @example "John Smith"
   */
  name?: string;
}

/**
 * UserRoleEnum
 * Ordered from highest permission to lowest permission.
 */
export enum UserRoleEnum {
  Admin = "Admin",
  OrganizationAdmin = "OrganizationAdmin",
  Analyst = "Analyst",
  Client = "Client",
  Guest = "Guest",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** ViewModel */
export interface ViewModel {
  /**
   * Settings
   * The setting definitions for different types of views. Key options: adjacency_map, single_asset_map, group_asset_map, risk_ratings_graph, risk_metrics_comparison_graph, highest_risk_assets_table, risk_surface_area_graph, aggregate_statistics, header_labels, asset_header_labels, portfolio_map, risk_threshold, asset_data_grid, units.
   */
  settings: Record<
    string,
    | SingleAssetMapSetting
    | GroupAssetMapSetting
    | AdjacencyMapSetting
    | RiskRatingsGraphSetting
    | RiskMetricsGraphSetting
    | HighestRiskTableSetting
    | RiskSurfaceAreaGraphSetting
    | AggregateStatisticsSetting
    | HeaderLabelSetting
    | AssetHeaderLabelSetting
    | PortfolioMapSetting
    | RiskThresholdSetting
    | BaseDataGridSetting
    | UnitsSetting
  >;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === "number" ? value : `${value}`
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string"
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${
        queryString ? `?${queryString}` : ""
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title IRIS Client Service
 * @version 0.1.0
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  ping = {
    /**
     * No description
     *
     * @tags ping
     * @name GetPaginatedAssets
     * @summary Get Paginated Assets
     * @request GET:/ping/
     * @secure
     */
    getPaginatedAssets: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/ping/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  organization = {
    /**
     * No description
     *
     * @tags organization
     * @name GetPaginatedOrganization
     * @summary Get Paginated Organization
     * @request GET:/organization/
     * @secure
     */
    getPaginatedOrganization: (
      query?: {
        /** Cursor */
        cursor?: string;
        /** Limit */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedOrganizationResponseModel, HTTPValidationError>({
        path: `/organization/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name CreateOrganization
     * @summary Create Organization
     * @request POST:/organization/
     * @secure
     */
    createOrganization: (
      data: CreateOrganizationRequestModel,
      params: RequestParams = {}
    ) =>
      this.request<CreateOrganizationResponseModel, HTTPValidationError>({
        path: `/organization/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name GetOrganizationById
     * @summary Get Organization By Id
     * @request GET:/organization/{organization_id}
     * @secure
     */
    getOrganizationById: (organizationId: string, params: RequestParams = {}) =>
      this.request<CreateOrganizationResponseModel, HTTPValidationError>({
        path: `/organization/${organizationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name UpdateOrganization
     * @summary Update Organization
     * @request POST:/organization/{organization_id}
     * @secure
     */
    updateOrganization: (
      organizationId: string,
      data: CreateOrganizationRequestModel,
      params: RequestParams = {}
    ) =>
      this.request<CreateOrganizationResponseModel, HTTPValidationError>({
        path: `/organization/${organizationId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name DeleteOrganization
     * @summary Delete Organization
     * @request DELETE:/organization/{organization_id}
     * @secure
     */
    deleteOrganization: (organizationId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/organization/${organizationId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name AssignUserToOrganization
     * @summary Assign User To Organization
     * @request POST:/organization/{organization_id}/assign_user/{username}
     * @secure
     */
    assignUserToOrganization: (
      organizationId: string,
      username: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/organization/${organizationId}/assign_user/${username}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization
     * @name GetOrganizationUsers
     * @summary Get Organization Users
     * @request GET:/organization/{organization_id}/users
     * @secure
     */
    getOrganizationUsers: (
      organizationId: string,
      params: RequestParams = {}
    ) =>
      this.request<CreateUserResponse[], HTTPValidationError>({
        path: `/organization/${organizationId}/users`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization/settings
     * @name GetConsequencesForSettings
     * @summary Get Consequences For Settings
     * @request GET:/organization/settings/consequences
     * @secure
     */
    getConsequencesForSettings: (
      query: {
        /** An enumeration. */
        setting: SettingNameEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<string[], HTTPValidationError>({
        path: `/organization/settings/consequences`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization/settings
     * @name GetOrganizationSettings
     * @summary Get Organization Settings
     * @request GET:/organization/{organization_id}/settings
     * @secure
     */
    getOrganizationSettings: (
      organizationId: string,
      params: RequestParams = {}
    ) =>
      this.request<CreateSettingsResponse, HTTPValidationError>({
        path: `/organization/${organizationId}/settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization/settings
     * @name CreateDefaultOrganizationSettings
     * @summary Create Default Organization Settings
     * @request POST:/organization/{organization_id}/settings
     * @secure
     */
    createDefaultOrganizationSettings: (
      organizationId: string,
      params: RequestParams = {}
    ) =>
      this.request<CreateSettingsResponse, HTTPValidationError>({
        path: `/organization/${organizationId}/settings`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization/settings
     * @name UpdateOrganizationSettings
     * @summary Update Organization Settings
     * @request PATCH:/organization/{organization_id}/settings
     * @secure
     */
    updateOrganizationSettings: (
      organizationId: string,
      data: UpdateOrganizationSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<CreateSettingsResponse, HTTPValidationError>({
        path: `/organization/${organizationId}/settings`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization/settings
     * @name GetIndividualViewSettings
     * @summary Get Individual View Settings
     * @request GET:/organization/{organization_id}/settings/{view_id}
     * @secure
     */
    getIndividualViewSettings: (
      organizationId: string,
      viewId: string,
      query: {
        /** An enumeration. */
        view: InstanceViewEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<CreateSettingsResponse, HTTPValidationError>({
        path: `/organization/${organizationId}/settings/${viewId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization/settings
     * @name CreateIndividualViewSettings
     * @summary Create Individual View Settings
     * @request POST:/organization/{organization_id}/settings/{view_id}
     * @secure
     */
    createIndividualViewSettings: (
      organizationId: string,
      viewId: string,
      query: {
        /** An enumeration. */
        view: InstanceViewEnum;
      },
      data: CreateViewSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<CreateSettingsResponse, HTTPValidationError>({
        path: `/organization/${organizationId}/settings/${viewId}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization/settings
     * @name DeleteIndividualViewSettings
     * @summary Delete Individual View Settings
     * @request DELETE:/organization/{organization_id}/settings/{view_id}
     * @secure
     */
    deleteIndividualViewSettings: (
      organizationId: string,
      viewId: string,
      query: {
        /** An enumeration. */
        view: InstanceViewEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, HTTPValidationError>({
        path: `/organization/${organizationId}/settings/${viewId}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags organization/settings
     * @name UpdateIndividualViewSettings
     * @summary Update Individual View Settings
     * @request PATCH:/organization/{organization_id}/settings/{view_id}
     * @secure
     */
    updateIndividualViewSettings: (
      organizationId: string,
      viewId: string,
      query: {
        /** An enumeration. */
        view: InstanceViewEnum;
      },
      data: CreateViewSettingsRequest,
      params: RequestParams = {}
    ) =>
      this.request<CreateSettingsResponse, HTTPValidationError>({
        path: `/organization/${organizationId}/settings/${viewId}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags user
     * @name CreateUser
     * @summary Create User
     * @request POST:/user/
     * @secure
     */
    createUser: (data: CreateUserRequestModel, params: RequestParams = {}) =>
      this.request<CreateUserResponse, HTTPValidationError>({
        path: `/user/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UpdateMyAttributes
     * @summary Update My Attributes
     * @request PATCH:/user/me
     * @secure
     */
    updateMyAttributes: (
      data: UpdateUserAttributesRequestModel,
      params: RequestParams = {}
    ) =>
      this.request<CreateUserResponse, HTTPValidationError>({
        path: `/user/me`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UpdateMyRoleToGuest
     * @summary Update My Role To Guest
     * @request PATCH:/user/me/role
     * @secure
     */
    updateMyRoleToGuest: (params: RequestParams = {}) =>
      this.request<CreateUserResponse, any>({
        path: `/user/me/role`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name DeleteUser
     * @summary Delete User
     * @request DELETE:/user/{username}
     * @secure
     */
    deleteUser: (username: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/user/${username}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UpdateUserAttributes
     * @summary Update User Attributes
     * @request PATCH:/user/{username}
     * @secure
     */
    updateUserAttributes: (
      username: string,
      data: AdminUpdateUserAttributesRequestModel,
      params: RequestParams = {}
    ) =>
      this.request<CreateUserResponse, HTTPValidationError>({
        path: `/user/${username}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Update a user's role. Admins can only update another user's role Args: username (str): Username of the user to update user_role (UserRoleEnum): User role to update to user_info (UserInfo, optional): User Info Session. Defaults to Depends(get_user_info). Raises: exceptions.HTTPException: If the user is not an admin Returns: CreateUserResponse: Response model for Created User
     *
     * @tags user
     * @name UpdateUserRole
     * @summary Update User Role
     * @request PATCH:/user/{username}/role
     * @secure
     */
    updateUserRole: (
      username: string,
      query: {
        /** Ordered from highest permission to lowest permission. */
        user_role: UserRoleEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<CreateUserResponse, HTTPValidationError>({
        path: `/user/${username}/role`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  featureFlags = {
    /**
     * @description Get the feature flags for an Organization. If no organization ID is provided then the feature flags for all organizations is returned.
     *
     * @tags feature-flags
     * @name GetOrganizationFeatureFlags
     * @summary Get Organization Feature Flags
     * @request GET:/feature-flags/
     * @secure
     */
    getOrganizationFeatureFlags: (
      query?: {
        /** Organization Id */
        organization_id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Record<string, FeatureFlag>, HTTPValidationError>({
        path: `/feature-flags/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags feature-flags
     * @name UpsertGlobalFeatureFlag
     * @summary Upsert Global Feature Flag
     * @request POST:/feature-flags/
     * @secure
     */
    upsertGlobalFeatureFlag: (
      query: {
        /** Flag Key */
        flag_key: string;
      },
      data: FeatureFlag,
      params: RequestParams = {}
    ) =>
      this.request<Record<string, FeatureFlag>, HTTPValidationError>({
        path: `/feature-flags/`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags feature-flags
     * @name DeleteGlobalFeatureFlag
     * @summary Delete Global Feature Flag
     * @request DELETE:/feature-flags/
     * @secure
     */
    deleteGlobalFeatureFlag: (
      query: {
        /** Flag Key */
        flag_key: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<Record<string, FeatureFlag>, HTTPValidationError>({
        path: `/feature-flags/`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags feature-flags
     * @name UpdateFeatureFlag
     * @summary Update Feature Flag
     * @request PATCH:/feature-flags/{organization_id}
     * @secure
     */
    updateFeatureFlag: (
      organizationId: string,
      query: {
        /** Flag Key */
        flag_key: string;
        /** Enabled */
        enabled?: boolean;
      },
      data: Specification,
      params: RequestParams = {}
    ) =>
      this.request<Record<string, FeatureFlag>, HTTPValidationError>({
        path: `/feature-flags/${organizationId}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
