import type { UnitType } from "@/apis/services/HazardService";

export const formatMoney = (
  currency?: number | null,
  maxDigits: number = 2,
  unit?: UnitType | null
) => {
  if (currency === null || currency === undefined) return "-";

  // anything above 1000 does not need to have any digits to keep a cleaner UI
  const digits = currency > 1000 ? 0 : maxDigits;
  const value = Intl.NumberFormat("en", {
    notation: "compact",
    style: "currency",
    currency: unit ?? "USD",
    maximumFractionDigits: digits,
    currencyDisplay: "symbol",
  }).format(currency);

  return value;
};

export const deepMerge = (target: any, source: any) => {
  const merged = { ...target };

  for (const key in source) {
    if (
      source[key] instanceof Object &&
      key in target &&
      target[key] instanceof Object
    ) {
      merged[key] = deepMerge(target[key], source[key]);
    } else {
      merged[key] = source[key];
    }
  }

  return merged;
};

export const formatNumber = (
  number: number,
  notation?: "standard" | "scientific" | "engineering" | "compact"
) =>
  Intl.NumberFormat("en", {
    notation: notation ?? "compact",
  }).format(number);

export const formatAmount = (amount?: number) =>
  amount?.toLocaleString() || "-";

export const asyncForEach = async <T>(array: T[], callback: Function) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const hasNullValue = (obj: Object) => {
  const arr = Object.entries(obj).filter(([key]) => key !== "risk_rating");
  return arr.every(([, value]) => {
    if (value === undefined || value === null || value === "") {
      return true;
    }

    return false;
  });
};

export const objectExists = (o: any) => {
  return o !== null && o !== undefined;
};

export const isEmptyObject = (obj?: Object | null) => {
  if (!obj) return true;
  if (Object.keys(obj).length === 0) return true;
  if (Object.values(obj).every((value) => !value)) return true;
  return false;
};

export const stopPropagation = (e: any) => {
  e.stopPropagation();
};

export const getDataToSave = (data: any) => {
  const newData: any = { ...data };

  for (const [key, value] of Object.entries(data)) {
    // check if something is an empty string and if yes, set the value to undefined
    if (value === "") {
      newData[key] = null;
    }
  }
  return newData;
};

export const isFilterByExist = (
  args: (string | undefined)[],
  filterBy: string
) => {
  const lowerCaseArgs = args.map((arg) => arg?.toLowerCase());
  return lowerCaseArgs.some((arg) => arg?.includes(filterBy.toLowerCase()));
};

export const isLatitude = (num: number) => isFinite(num) && Math.abs(num) <= 90;

export const isLongitude = (num: number) =>
  isFinite(num) && Math.abs(num) <= 180;

export const filterOutObjectByKey = (object: Object, keyToFilter: string) => {
  if (isEmptyObject(object)) return {};
  if (!isEmptyObject(object) && !keyToFilter) return object;
  return Object.fromEntries(
    Object.entries(object).filter(([key]) => key !== keyToFilter)
  );
};
